import request from '@/utils/request'
import constant from '@/constant'

function swiperList(formData){
  return request.post(constant.serverUrl + "/open/newsApi/swiperList", formData);
}

function tabList(formData){
  return request.post(constant.serverUrl + "/open/newsApi/tabList", formData);
}

function pageList(formData){
  return request.post(constant.serverUrl + "/open/newsApi/pageList", formData);
}

function detail(formData){
  return request.post(constant.serverUrl + "/open/newsApi/detail" , formData);
}

export default {
  swiperList,tabList,pageList,detail
}