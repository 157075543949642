<template>
<!-- 分页导航 -->
  <div class="nav">
      <div class="menu">
        <ul id="nav">
          <li class="item" v-for="(item, index) in navigationBar" :key="index" >
            <router-link :to="item.to" exact="">{{ item.title }}</router-link>
          </li>
        <li class="item"><a href="http://bbs.jsrailway.com.cn:88/" target="_blank">铁路论坛</a></li>
          <li class="item"><a href="http://oa.jsrailway.com.cn:8080/" target="_blank">OA办公</a></li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0,
      navigationBar: [
        { title: "首页", to: "/index/" },
        { title: "铁路概况", to: "/list/tlgk" },
        { title: "机构设置", to: "/list/jgsz" },
        { title: "政务动态", to: "/list/zwdt" },
        { title: "党建动态", to: "/list/djdt" },
        { title: "法治宣传", to: "/list/fzxc" },
        { title: "为您服务", to: "/list/wnfw" },
        { title: "职工之家", to: "/#/" },
        // { title: "铁路论坛", to: "http://bbs.jsrailway.com.cn:88/" },
        // { title: "OA办公", to: "http://oa.jsrailway.com.cn:8080/" },
      ],
    };
  },
  methods: {
  },
   watch: {
  
      $route() {
            location.reload();
  
                    },
  
       },
};
</script>

<style lang="scss" scoped>
.nav {
	font-family: Regular;
  width: 100%;
  background-color: #1f4a99;
  height: 52px;
  .menu {
    max-width: 1220px;
	margin: 0 auto;
    #nav {
      max-width: 1220px;
	  margin: 0 auto;
      display: flex;
      cursor: pointer;
      .item {
        width: 122px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        margin-top: 4px;
        border-radius: 4px;
        background-color: #1f4a99;
        font-size: 20px;
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }

    }
  }
}

.router-link-active{
	width: 100%;
   background-color: #fff;
   color: rgba(31, 74, 153, 1) !important;
   line-height: 44px;
   height: 44px ;
   display: inline-block;
   border-radius: 4px;
  }

</style>