<template>
  <div class="main">
    <!-- 头部 -->
    <top></top>
    <!-- 导航 -->
    <navigation></navigation>
    <div class="breadcrumb">
      <div class="w">
        <div class="icon"><i class="el-icon-s-home"></i></div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ formModel.typeName }}</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="text-head">
        <h3>{{ formModel.title }}</h3>
        <div class="text-info">
          <div class="date">
            <div class="title">发布时间:</div>
            <div class="value">{{ formModel.createTime }}</div>
          </div>
          <div class="source">
            <div class="title">来源:</div>
            <div class="value">{{ formModel.sourceName }}</div>
          </div>
          <!-- <div class="font">
            <div class="title">字号:</div>
            <div class="value">TT</div>
          </div> -->
        </div>
      </div>
      <div class="text-part" v-html="formModel.content"></div>
      <div class="text-else">
        <div class="last">
          <div class="title">上一篇:</div>
          <div
            v-if="formModel.lastId != null"
            class="value"
            @click="toDetail(formModel.lastId)"
          >
            {{ formModel.lastName }}
          </div>
          <div v-else class="value">无</div>
        </div>
        <div class="next">
          <div class="title">下一篇:</div>
          <div
            v-if="formModel.nextId != null"
            class="value"
            @click="toDetail(formModel.nextId)"
          >
            {{ formModel.nextName }}
          </div>
          <div v-else class="value">无</div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from "../components/Bottom.vue";
import Navigation from "../components/Navigation.vue";
import Top from "../components/Top.vue";
import newsApi from "@/api/news";

export default {
  components: {
    Navigation,
    Top,
    Bottom,
  },
  data() {
    return {
      formModel: {},
      newsId: this.$route.query.id,
    };
  },
  methods: {
    showInfo() {
      var self = this;
      var formData = new FormData();
      formData.append("id", this.newsId);

      newsApi
        .detail(formData)
        .then(function (response) {
          var jsonData = response.data;
          if (jsonData.result) {
            self.formModel = jsonData.data;
          }
        })
        .catch((error) => {
          self.$message.error("获取数据发生错误！");
        });
    },
    toDetail(id) {
      let pathInfo = this.$router.resolve({
        path: "/detail",
        query: {
          id: id,
        },
      });
      window.open(pathInfo.href);
    },
  },
  mounted: function () {
    this.showInfo();
  },
};
</script>

<style lang="scss" scoped>
	
.main {
  min-width: 1139px;
  width: 100%;
}
.w {
  width: 1220px;
}
// 导航
.nav {
  width: 100%;
  background-color: #1f4a99;
  height: 52px;
  .menu {
    width: 100%;
    padding: 0 110px;
    #nav {
      max-width: 1220px;
      display: flex;
      cursor: pointer;

      .item {
        width: 10%;
        height: 44px;
        line-height: 44px;
        text-align: center;
        margin-top: 4px;
        border-radius: 4px;
        background-color: #1f4a99;
        font-size: 20px;
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }

      .checked {
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 1);
        a {
          text-decoration: none;
          color: rgba(31, 74, 153, 1);
        }
      }
    }
  }
}

.w {
  width: 1220px;
  display: flex;
  margin: 0 auto;
}

.breadcrumb {
  height: 120px;
  background-color: rgba(233, 234, 236, 1) !important;
  background: url(../assets/img/breadcrumb.png);
  background-repeat: no-repeat;
  background-size: 43.6% 93.2%;
  background-position: 90% 100%;
  padding: 48px 110px;
  line-height: 24px;
  margin-bottom: 0;
  .icon {
    font-size: 24px;
    color: #1f4a99;
  }
  .el-breadcrumb {
    font-family: "Regular";
    line-height: 24px;
    margin-left: 4px;
  }
  /deep/.el-breadcrumb__inner.is-link {
    color: #1f4a99 !important;
  }
}

// 内容
.content {
  max-width: 976px;
  margin: 40px auto;

  min-height: calc(100vh - 150px);

  .text-head {
    width: 100%;
    h3 {
      font-size: 28px;
      text-align: center;
      font-family: "SemiBold";
    }
    .text-info {
      color: rgba(119, 119, 119, 1);
      margin-top: 40px;
      display: flex;
      justify-content: center;
      font-family: "Regular";
      .date,
      .source,
      .font {
        display: flex;
        color: rgba(119, 119, 119, 1);

        .title {
          margin-right: 8px;
        }
      }
      .source,
      .font {
        margin-left: 40px;
      }
    }
  }

  .text-part {
    font-family: "Light";

    margin-top: 40px;
    p {
      text-indent: 28px;
      line-height: 32px;
    }
  }
  .text-else {
    margin-top: 52px;
    .last,
    .next {
      line-height: 40px;
      display: flex;
      font-family: Roboto;
      .value {
        margin-left: 8px;
      }
    }
    .last {
      color: #777777;
    }
  }
}
</style>
