<template>
      <!-- 底部 -->
    <div class="footer">
      <div class="w">
		  <div class="left">
		    <div class="blogroll">
		      <span>友情链接</span>
		      <el-select v-model="queryZFWZ" placeholder="政府网站" filterable size="mini" class="select2">
		        <el-option v-for="item in listZFWZ" :key="item.id" :label="item.text" :value="item.url"></el-option>
		      </el-select>
		      <el-select v-model="queryXGWZ" placeholder="相关网站" filterable size="mini" class="select2">
		        <el-option v-for="item in listXGWZ" :key="item.id" :label="item.text" :value="item.url"></el-option>
		      </el-select>
		    </div>
		    <div class="copyright">
		      版权所有：湖北荆鹏软件开发有限公司
		      <div class="item">网站备案：鄂ICP备10020183-1号</div>
		      <div class="item">荆州市地方铁路有限公司</div>
		    </div>
		  </div>
		  <div class="right"><img src="../assets/img/badge.png" alt="" /></div>
	  </div>
    </div>
</template>

<script>
import linksApi from "@/api/links";

export default {
  data() {
    return {
      queryZFWZ: "",
      queryXGWZ: "",
      listZFWZ: [],
      listXGWZ: [],
    };
  },
  methods: {
    listZFWZData() {
      var self = this;

      var formData = new FormData();
      formData.append("typeId", "c4c3953f-90d1-4694-a0f4-1fc5aa39b3e4");

      linksApi.list(formData).then(function (response) {
        var jsonData = response.data.data;
        self.listZFWZ = jsonData;
      })
      .catch((error) => {
        self.$message.error("获取数据发生错误！");
      });
    },
    listXGWZData() {
      var self = this;

      var formData = new FormData();
      formData.append("typeId", "7d481c19-a70f-4138-9651-8fbf1e7c9cab");

      linksApi.list(formData).then(function (response) {
        var jsonData = response.data.data;
        self.listXGWZ = jsonData;
      })
      .catch((error) => {
        self.$message.error("获取数据发生错误！");
      });
    },
    goTo(url) {
      window.open(url, '_blank');
    }
  },
  mounted: function () {
    this.listZFWZData();
    this.listXGWZData();
  },
  watch: {
    "queryZFWZ"(newVal,oldVal) {
      this.goTo(newVal);
    },
    "queryXGWZ"(newVal,oldVal) {
      this.goTo(newVal);
    }
  }
}
</script>

<style lang="scss" scoped>
	
// 底部
.w{
	width: 1220px;
	margin: 0 auto;
	display: flex;
}
.footer {
  width: 100%;
  height: 120px;
  
  background-color: rgba(236, 237, 246, 1);
  border-top: 2px solid rgba(31, 74, 153, 1);
  padding: 24px 0px;
  
  .left {
    .blogroll {
		
      span {
		  font-family: SemiBold;
        display: inline-block;
        margin-top: 8px;
        line-height: 24px;
        color: rgba(31, 74, 153, 1);
        font-size: 16px;
      }
      select {
		font-family: Regular;
        cursor: pointer;
        text-align: center;
        max-width: 360px;
        min-width: 300px;
        height: 30px;
        line-height: 30px;
        margin-left: 24px;
		color: saddlebrown;
      }
      .select2 {
        margin-left: 16px;
      }
    }
    .copyright {
      margin-top: 12px;
      display: flex;
	  font-family: 'Regular';
	  font-weight: 600;
      .item {
        margin-left: 40px;
      }
    }
  }
  .right {
    margin-left: auto;
  }
}
/deep/.el-input--mini .el-input__inner{
	font-family: Microsoft Yahei;
}
</style>